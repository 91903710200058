body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-size: 16px;
  line-height: 20px;
  color: #333;
  font-family: Humanist521BT;
}

a {
  color: #4d3626;
}

a:hover {
  color: #996641;
}

@font-face {
  font-family: Humanist521BT;
  src: local('Humanist521BT'),
       local('Humanist521BT'),
       url(../fonts/Humanist521BT.woff) format('woff');
  font-style: normal;
  font-weight: 100;
}


@font-face {
  font-family: GoudyOldStyleBoldBT;
  src: local('GoudyOldStyleBoldBT'),
       local('GoudyOldStyleBoldBT'),
       url(../fonts/GoudyOldStyleBoldBT.woff) format('woff');
  font-style: normal;
  font-weight: 100;
}




.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background: #5c4a3f;
  border-color: #413026;
  text-shadow: none; /* Prevent inheritance from `body` */
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


.content-container {
  max-width: 42em;
  z-index: 90;
  min-height: 100vh;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: #656565;
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: #656565
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #000;
  border-bottom-color: #000;
}


.input-group > .form-control.code-input {
  font-size: 2rem;
  text-align: center;
  width: 100%;
  flex: initial;
  margin: auto;
}

.decor1, .decor2 {
	position: relative;
  overflow: hidden;
	position: absolute;
  width:100%;
  height:100vh;
	top: 0;
	left: 0;
	background-image: url(../images/doily2_opt.svg);
  background-size: 30%;
  background-repeat: no-repeat;
	background-position: top left;
  z-index: 1;
	opacity: 70%;
}

.decor2 {
	transform: scaleX(-1);
}



/*
 * Bootstrap overrides
 */

.form-label {
  margin-bottom: .1rem;
}


footer {
  color: #888;
}


.toast-container {
  right: 0;
  position: fixed;
}


.loadingIcon {
  opacity: 0;
}
.loadingIcon.active {
  opacity: 1;
  animation: fadeIn 0.3s;
}

.loadingIcon.fadeOut {
  opacity: 0;
  animation: fadeOut 2s;
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}