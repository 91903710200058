


/*
 * Base structure
 */


.hero {
  z-index: 90;
  padding-right: 10vh;
  padding-left: 15vw;
  color: #ffffff;
  text-align: center;
  font-family: Humanist521BT;
  font-size: 1.0rem;
}

.hero > h1.names {
  font-size: 3rem;
  line-height: 3.3rem;
  font-family: GoudyOldStyleBoldBT;
}

.hero > .lead {
  font-size: 2rem;
	line-height: 2rem;
}

.hero > .lead2 {
  font-size: 1.5rem;
	line-height: 1.5rem;
}



.hero-layout {
  background-color: #9a765e;
  background-size: cover;
  background-position: center center;
  background-image: url(../images/wood-grain-x.jpg);
	text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.hero-layout .decor1, .hero-layout .decor2 {
	position: relative;
  overflow: hidden;
	position: absolute;
  width:100%;
  height:100vh;
	top: 0;
	left: 0;
	background-image: url(../images/doily_opt.svg);
  background-size: 30%;
  background-repeat: no-repeat;
	background-position: top left;
  z-index: 1;
	opacity: 70%;
}

.hero-layout .decor2 {
	transform: rotate(180deg);
}

.hero-layout header {
margin-bottom: auto !important;
}

.hero-layout:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(135deg, #9a826eFF, #281407de);
}

.hero-layout > * {
  z-index: 2;
}

.hero-layout.h-100, .base-layout.h-100 {
	height: 100vh !important;
}


.hero-layout .nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
}


.hero-layout .nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}


.hero-layout .nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.hero-layout .btn{
  animation: bobble-delayed 5s infinite ease both;
}

@keyframes bobble-delayed {
  0% {transform: scale(1, 1);}
  80% {transform: scale(1, 1);}
  86% {transform: scale(1.05, 0.95);}
  88% {transform: scale(0.95, 1.05);}
  90% {transform: scale(1.03, 0.97);}
  93% {transform: scale(0.97, 1.03);}
  95% {transform: scale(1.01, 0.99);}
  100% {transform: scale(1, 1);}
}


@media only screen and (max-width: 768px) {
	header {
		margin-top: 50px;
	}

  .hero-layout .decor1, .hero-layout .decor2 {
		background-size: 50%;
  }

  .hero-layout {
    background-image: url(../images/wood-grain-y.jpg);
  }


  .hero {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  .hero > h1.names {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
  
  .hero > .lead {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  
  .hero > .lead2 {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
}